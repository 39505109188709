#videos {
  /*height: 740px;*/
  background-color: #fff;
  color: #fff;
  /*text-shadow: 0 1px 0 black;*/
  border: 0px solid #fff;
}

#main-videos {
  border: 6px solid #fff;
}

/* #video1 {

}

#video2 {
  
}

#video3 {
  
}

#video4 {
  
}

#video5 {
  
}

#video6 {
  
} */


.video-box {
  height: 315px;
  border: 6px solid white;
  width: 100%;
  padding: 0;

}

.video-item {
  width: 100%;
  height: 100%;
}

.video-header {
  color: #000;
  padding-top: 50px;
  padding-bottom: 10px;
}


