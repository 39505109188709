.show {
  display: block;
}

.hide {
  display: none;
}

.authmodal {
  color: black;
}

.auth-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}