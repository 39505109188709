#hero-wrapper {
  position: relative;
  /*height: 100%;*/
}

#hero {
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: 640px;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/
  .hero-line {
    font-size: 2rem;
  }
  .lead {
    font-size: .9rem;
  }

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
  #hero {

  }
  .hero-line {

  }
  .lead {

  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #hero {

  }
  .hero-line {
    font-size: 3.5rem;
  }
  .lead {
    font-size: 1.3rem;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #hero {
    padding-top: 160px;
    padding-bottom: 160px;
    min-height: 819px;
  }
  .hero-line {
    font-size: 4.5rem;
  }
  .lead {

  }
}







@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

#hero {
  -webkit-animation: fadeout 4s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadeout 4s; /* Firefox < 16 */
      -ms-animation: fadeout 4s; /* Internet Explorer */
       -o-animation: fadeout 4s; /* Opera < 12.1 */
          animation: fadeout 4s;
  animation-delay: 4s;
  animation-fill-mode: forwards;
}







@keyframes intro {
  0% {
    opacity: 0;
    /*z-index: 0;*/
  }
  50% {
    opacity: 1;
    /*z-index: 1;*/
  }
  100% {
    opacity: 0;
    /*z-index: 0;*/
  }
}

#hero-container {
  position: relative;
  /*z-index: 1;*/
  opacity: 0;
  animation: intro 8s;
}






@keyframes enterup {
    from { transform: translateY(40px); }
    to   { transform: translateY(0); }
}

/* Firefox < 16 */
@-moz-keyframes enterup {
    from { transform: translateY(40px); }
    to   { transform: translateY(0); }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes enterup {
    from { transform: translateY(40px); }
    to   { transform: translateY(0); }
}

/* Internet Explorer */
@-ms-keyframes enterup {
    from { transform: translateY(40px); }
    to   { transform: translateY(0); }
}

/* Opera < 12.1 */
@-o-keyframes enterup {
    from { transform: translateY(40px); }
    to   { transform: translateY(0); }
}

#hero-img {
  -webkit-animation: enterup 2s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: enterup 2s; /* Firefox < 16 */
      -ms-animation: enterup 2s; /* Internet Explorer */
       -o-animation: enterup 2s; /* Opera < 12.1 */
          animation: enterup 2s;
}













