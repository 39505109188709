#howto {
  padding-top: 160px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.howto-header {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.step-img {
  border-radius: 500px;
  margin-bottom: 20px;
}

.howto-subtitle {
  margin-bottom: 50px;
}

/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}