@font-face {
  font-family: 'Limerick-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Limerick-Regular.ttf'); /* IE9 Compat Modes */
  src: local('Limerick'), local('Limerick'),
       url('./fonts/Limerick-Regular.ttf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Limerick-Regular.ttf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Limerick-Regular.ttf') format('woff'), /* Modern Browsers */
       url('./fonts/Limerick-Regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Limerick-Regular.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Limerick-Light';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Limerick-Light.ttf'); /* IE9 Compat Modes */
  src: local('Limerick'), local('Limerick'),
       url('./fonts/Limerick-Light.ttf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Limerick-Light.ttf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Limerick-Light.ttf') format('woff'), /* Modern Browsers */
       url('./fonts/Limerick-Light.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Limerick-Light.ttf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Limerick-Xlight';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Limerick-Xlight.ttf'); /* IE9 Compat Modes */
  src: local('Limerick'), local('Limerick'),
       url('./fonts/Limerick-Xlight.ttf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Limerick-Xlight.ttf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Limerick-Xlight.ttf') format('woff'), /* Modern Browsers */
       url('./fonts/Limerick-Xlight.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Limerick-Xlight.ttf') format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'Watchworld-Thin';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Watchword_Thin.otf'); /* IE9 Compat Modes */
  src: local('Watchworld'), local('Watchworld'),
       url('./fonts/Watchword_Thin.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Watchword_Thin.otf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Watchword_Thin.otf') format('woff'), /* Modern Browsers */
       url('./fonts/Watchword_Thin.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Watchword_Thin.otf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Watchworld-Hairline';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Watchword_Hairline.otf'); /* IE9 Compat Modes */
  src: local('Watchworld'), local('Watchworld'),
       url('./fonts/Watchword_Hairline.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Watchword_Hairline.otf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Watchword_Hairline.otf') format('woff'), /* Modern Browsers */
       url('./fonts/Watchword_Hairline.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Watchword_Hairline.otf') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Watchworld-Regular';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Watchword_Regular.otf'); /* IE9 Compat Modes */
  src: local('Watchworld'), local('Watchworld'),
       url('./fonts/Watchword_Regular.otf') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/Watchword_Regular.otf') format('woff2'), /* Super Modern Browsers */
       url('./fonts/Watchword_Regular.otf') format('woff'), /* Modern Browsers */
       url('./fonts/Watchword_Regular.otf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/Watchword_Regular.otf') format('svg'); /* Legacy iOS */
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Limerick-Light', Helvetica Neue, Helvetica, sans-serif;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  letter-spacing: 5px;
  text-align: center;
  /*font-size: 62.5%;*/
}

section {
}


