#products {
  padding-top: 110px;
  padding-bottom: 110px;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );
}

#products-main {
  height: 1px;
  width: 100px;
  margin-top: -45px;
  margin-bottom: 45px;  
}

.title {
  margin-bottom: 50px;
}

.chair-main-img {
  height: 330px;
}

.chair-selection-section {
  margin-bottom: 30px;
}

.chair-name {
  margin-bottom: 8px;
}

.chair-img {
  margin-bottom: 15px;

  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;

  top: 0px;
  display: inline-block;
  position: relative;

  box-shadow: 0px 0px 0px rgba( 197, 197, 197, 0.1 );

  -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
          transform: translateZ(0);
  
  -webkit-transition: all 0.2s ease;
     -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.chair-img:hover {
  top: -5px;
  box-shadow: 0px 5px 10px rgba( 197, 197, 197, 0.2 );
}

.chair-img:active {
  top: 0px;
}


.chair-section {
  margin-bottom: 20px;
}

.seal {
  position: absolute;
  right: 0;
  margin-right: 50px;
  width: 75px;
}

.lead-legal {
  font-size: 12px;
  letter-spacing: .1rem;
  margin-top: 10px;
  color: #636363;
}

.lead-msrp {
  margin-bottom: 40px;
}


.line {
  width: 3px;
  position: absolute;
  left: 50%;
  margin-left: -3px;

  background: rgba(76,76,76,1);
  background: -moz-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76,76,76,1)), color-stop(0%, rgba(102,102,102,1)), color-stop(25%, rgba(71,71,71,1)), color-stop(50%, rgba(44,44,44,1)), color-stop(75%, rgba(17,17,17,1)), color-stop(100%, rgba(17,17,17,1)));
  background: -webkit-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  background: -o-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  background: -ms-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  background: linear-gradient(to bottom, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#111111', GradientType=0 );

  display: block;
}

.linechair {
  height: 250px;
}

.linebowl {
  height: 250px;
}

.linebase {
  height: 85px;
}

.color-title {
  font-size: .8rem;
}

.colorbox {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;


  height: 50px;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 5px;
  top: 0px;
  display: inline-block;
  position: relative;

  box-shadow: 0px 0px 0px rgba( 197, 197, 197, 0.1 );

  -webkit-transform: translateZ(0);
     -moz-transform: translateZ(0);
      -ms-transform: translateZ(0);
          transform: translateZ(0);
  
  -webkit-transition: all 0.2s ease;
     -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.colorbox:hover {
  top: -5px;
  box-shadow: 0px 5px 10px rgba( 197, 197, 197, 0.2 );
}

.colorbox:active {
  top: 0px;
}

.vip-height-mod {
  height: 175px;
}









/**********************************************/
/***** MOBILE SELECT COLOR BAR PROPERTIES *****/
/**********************************************/

/*****/
/* CHAIRCOLOR SELECT BAR */
/*****/
#chaircolor-select-bar {
  padding: 5px;
  padding-bottom: 0px;
  overflow-x: scroll;
  white-space: nowrap;

}
#chaircolor-select-bar:target {
  background-color: red;
}

.chaircolor-select-bar-title {
  float: left;
  color: #000;
  font-size: 1.25rem;
  letter-spacing: .1rem;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
/*****/
/* /CHAIRCOLOR SELECT BAR */
/*****/


/*****/
/* BASECOLOR SELECT BAR */
/*****/
#bowlcolor-select-bar {
  padding: 5px;
  padding-bottom: 0px;
  overflow-x: scroll;
  white-space: nowrap;
  
}
.bowlcolor-select-bar-title {
  float: left;
  color: #000;
  font-size: 1.25rem;
  letter-spacing: .1rem;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;  
}
/*****/
/* /BASECOLOR SELECT BAR */
/*****/

/*****/
/* BOTH */
/*****/
.colorbox-bar {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;


  height: 50px;
  width: 72px;
  margin-right: 5px;
  top: 0px;
  display: inline-block;
  position: relative;

  box-shadow: 0 0 5px rgba(97, 97, 97, .1);
  border: 1px solid rgba(97, 97, 97, .1);
  
  -webkit-transition: all 0.2s ease;
     -moz-transition: all 0.2s ease;
      -ms-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.colorbox-bar:hover {
  top: -3px;
  box-shadow: 0 0 5px rgba(97, 97, 97, .1);
  border: 1px solid rgba(97, 97, 97, .1);
}

.colorbox-bar:active {
  top: 0px;
  box-shadow: 0 0 5px rgba(97, 97, 97, 1);
  border: 1px solid rgba(97, 97, 97, 1);
}

.colorbox-bar-img {
  width: 100%;
  height: 100%;
}


.fixed-bottom-custom {
  position: fixed;
  right: 0;
  bottom: -250px;
  left: 0;
  z-index: 1030;
  transition: bottom .1s linear 0s;
}

.scrollingOver {
  transition: bottom .1s linear 0s;
  bottom: 0px;
}

/*****/
/* BOTH */
/*****/



/**********************************************/
/***** MOBILE SELECT COLOR BAR PROPERTIES *****/
/**********************************************/





















/**********************************************/
/***** COLORS *****/
/**********************************************/


.black {
  background-color: rgb(0, 0, 0, 1);
}

.white {
  background-color: rgb(255, 255, 255, 1);
}

.fossilgrey {
  /*background-color: rgb(186, 182, 178, 1);*/
  background-color: rgb(162, 149, 135, 1);
}

.cappuccino {
  background-color: rgb(194, 145, 87, 1); 
}

.ivory {
  /*background-color: rgb(255, 255, 240, 1);*/
  background-color: rgb(255, 255, 241, 1);
}

.burgundy {
  /*background-color: rgb(128, 0, 38, 1);*/
  background-color: rgb(140, 2, 31, 1);
}

.darkchocolate {
  background-color: rgb(67, 57, 50, 1);
  /*background-color: rgb(44, 24, 0, 1);*/
}

/*.rosegold {
  background: rgba(240,133,179,1);
  background: -moz-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(240,133,179,1)), color-stop(51%, rgba(240,161,195,1)), color-stop(100%, rgba(142,69,116,1)));
  background: -webkit-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: -o-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: -ms-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: linear-gradient(to right, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f085b3', endColorstr='#8e4574', GradientType=1 );
}*/

/*.gold {
  background: rgba(191,145,46,1);
  background: -moz-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(191,145,46,1)), color-stop(51%, rgba(246,255,122,1)), color-stop(100%, rgba(128,85,4,1)));
  background: -webkit-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: -o-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: -ms-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: linear-gradient(to right, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf912e', endColorstr='#805504', GradientType=1 );
}*/

/*.pearl {
  background-color: rgb(255, 255, 240, 1);
}

.graphite {
  background-color: rgb(129, 130, 130, 1);  
}

.mocha {
  background-color: rgb(67, 57, 50, 1);
}*/


/******************/
/***** /COLORS *****/
/******************/























/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/
  .lead-products {
      font-size: 1.5rem;
  }
  .display-4 {
    font-size: 2rem;
  }
  .col-custom {
    flex: 0 0 50%;
    max-width: 50%;    
  }
/* For all sizes */
@media(min-width: 0px) {
  #spachair-colors-display, .linechair, .linebowl, .linebase {
    display: none;
  }
}
/* Mobile L devices (425px and up) */
@media (min-width: 425px) {
  .linechair, .linebowl, .linebase {
    display: block;
  }
}
/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .lead-products {
      font-size: 1.5rem;
  }
  .seal {
    margin-right: 130px;
  }
  .col-custom {
    flex: 0 0 50%;
    max-width: 50%;    
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #products {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .line {
    display: none;
  }
  .display-4 {
    font-size: 2.5rem;
  }
  .seal {
    margin-right: 25px;
  }
  .col-custom {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .vip-height-mod {
    height: 200px;
  }  
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .line {
    display: block;
  }
  .color-title {
    font-size: .6rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .seal {
    margin-right: 30px;
  }
  .col-custom {
    flex: 0 0 50%;
    max-width: 50%;    
  }  
  #chaircolor-select-bar {
    padding: 5px;
    display: none;
  }
  #bowlcolor-select-bar {
    padding: 5px;
    display: none;
  }  
  #width-max-lg {
    max-width: 380px;
  }
  #spachair-colors-display {
    display: block;
  }
  .vip-height-mod {
    height: 335px;
  }   
  .chair-main-img {
    height: 290px;
  }  
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .color-title {
    font-size: .8rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .seal {
    margin-right: 45px;
  }
  .col-custom {
    flex: 0 0 50%;
    max-width: 50%;    
  }  
  .chair-main-img {
    height: 400px;
  }  
}









