.social-btns-side {
  position: fixed;
  top: 30%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1001;
  display: none;
}

/* Style the icon bar links */
.social-btns-side a {
  display: block;
  text-align: center;
  padding: 12px;
  /*transition: all 0.3s ease;*/
  color: white;
  /*font-size: 20px;*/
}



.social-btns-side .btn,
.social-btns-side .btn:before,
.social-btns-side .btn .fa {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns-side .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns-side .btn .fa {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.social-btns-side .btn.facebook:before {
  background-color: #3b5998;
}
.social-btns-side .btn.facebook .fa {
  /* color: #3b5998; */
  color: #fff;
}
.social-btns-side .btn.youtube:before {
  background-color: #ec252f;
}
.social-btns-side .btn.youtube .fa {
  /* color: #ec252f; */
  color: #fff;
}
.social-btns-side .btn.instagram:before {
  background-color: #517ea4;
}
.social-btns-side .btn.instagram .fa {
  /* color: #517ea4; */
  color: #fff;
}
.social-btns-side .btn.yelp:before {
  background-color: #b00;
}
.social-btns-side .btn.yelp .fa {
  /* color: #b00; */
  color: #fff;
}
.social-btns-side .btn:focus:before,
.social-btns-side .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns-side .btn:focus .fa,
.social-btns-side .btn:hover .fa {
  color: #fff;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.social-btns-side .btn {
  background-color: #2a2a2a;
  width: 50px;
  height: 50px;
  line-height: 0px;
  margin: 0 0px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 0%;
  opacity: 0.99;
}
.social-btns-side .btn:before {
  content: '';
  width: 120%;
  height: 120%;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.social-btns-side .btn .fa {
  font-size: 25px;
  vertical-align: middle;
  margin-bottom: 15px;
}




/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .social-btns-side {
    display: block;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}