#nomagnets {
  padding-top: 60px;
  padding-bottom: 60px;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.nomagnets-title {
  font-size: 1.5rem;
}

.nomagnets-subtitle {
  font-size: 1rem;
  margin-bottom: 30px;
}

.nomagnets-description {
  letter-spacing: .1rem;
  text-transform: none;
}

#nomagnets .card {
  color: #000;
  letter-spacing: .1rem;
  margin-bottom: 30px;
}

.nomagnets-link {
  text-transform: uppercase;
}

.nomagnets-video {
  height: 300px;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
/* @media (min-width: 576px) {
  #nomagnets {

  }
  .nomagnets-title {

  }
  .nomagnets-subtitle {
    
  }
} */

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  /* #nomagnets {
    padding-top: 160px;
    padding-bottom: 160px;
  } */
  .nomagnets-title {
    font-size: 2.5rem;
  }
  .nomagnets-subtitle {
    font-size: 1.75rem;
  }
}

/*// Large devices (desktops, 992px and up)*/
/* @media (min-width: 992px) {
  #nomagnets {

  }
  .nomagnets-title {

  }
  .nomagnets-subtitle {
    
  }
} */

/*// Extra large devices (large desktops, 1200px and up)*/
/* @media (min-width: 1200px) {
  #nomagnets {

  }
  .nomagnets-title {

  }
  .nomagnets-subtitle {
    
  }
} */