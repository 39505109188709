#notification {
  border: 12px solid white;
  border-bottom: 0px;
}

.noti-img {
  /* border: 6px solid white; */
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .noti-img {

  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .noti-img {

  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .noti-img {
    max-width: 50%;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .noti-img {

  }
}