#GoogleAuthBtn {
  border-color: #333;
  background-color: #fff;
  color: #333;

  border: 1px solid;
  font-family: inherit;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 9px 16px;
  text-align: center;
  vertical-align: middle; 
}

#GoogleAuthIcon {
  width: 20px;
  margin-right: 20px;
  margin-bottom: 4px;
}

#GoogleAuthBtn:hover {
  background-color: #d4d4d4;
  text-decoration: none;
}