#FacebookAuthBtn {
  border-color: #333;
  background-color: #3b5998;
  color: #fff;

  border: 1px solid;
  font-family: inherit;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 9px 6px;
  text-align: center;
  vertical-align: middle; 
}

#FacebookAuthIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 4px;
}

#FacebookAuthBtn:hover {
  background-color: #294175;
  text-decoration: none;
}