.accessories-chairs-title {
  margin-top: 40px;
}

/*.accessories-chairs-titles {
  font-size: .5rem;
}*/

.accessories-chairs-section {
  margin-bottom: 40px;
}

.accessories-chairs {
  margin: 5px;
}

.accessories-title {
  margin-top: 80px;
}

.lead-products-table {
  margin-top: 40px;
}
@media(min-width: 992px) {
  #accessories-color-display {
    display: block !important;
  }
}