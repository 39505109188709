#herovid {
  position: absolute;
  display: block;
  padding: 0;
  overflow: hidden;

  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  animation: fadein 4s;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  opacity: 0;

}

@keyframes fadein {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}



    



/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  #herovid {

  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #herovid {

  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #herovid {

  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #herovid {

  }
}





