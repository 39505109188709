#contact {
  padding-top: 60px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.contact-title {
  margin-bottom: 30px;
}

.contact-info {
  letter-spacing: .1rem;
}

.map {
  width: 100%;
  height: 100%;
}