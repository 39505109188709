#faq {
  color: #000;
  padding-top: 60px;
  padding-bottom: 160px;
  letter-spacing: .1rem;
  text-transform: capitalize;
  line-height: 1.25rem;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

#faq ul a {
  color: #fff;
}

.accordion button {
  color: #000;
  white-space: normal;
}

.accordion button:hover {
  color: #000;
}

#faq ul li {
  margin-bottom: 5px;
}


#faq-title {
  margin-bottom: 30px;
  color: #fff;
}

#faq-toc {
  color: #fff;
  text-align: left;
}

#faq-toc .toc-btn-custom {
  white-space: normal;
  color: #fff;
  text-align: left;
}

.btn-link {
  text-transform: capitalize;
  font-weight:bold;
}

.faq-toc-headers {
  text-align: center;
}

/*.card {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.card-header {
  overflow-wrap: break-word;
  word-wrap: break-word;
}*/

.card-body {
  text-transform: none;
  padding-left: 8%;
  padding-right: 8%;
}
