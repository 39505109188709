#footer {
  background: #2a2a2a;
  color: #6e6e6e;
  font-size: 12px;
  padding: 70px 0 50px;
  letter-spacing: .01rem;
  text-align: center;
}



/*@import '//codepen.io/chrisdothtml/pen/ojLzJK.css';*/
.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .fa {
  transition: all 0.35s;
  transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns .btn .fa {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.social-btns .btn.facebook:before {
  background-color: #3b5998;
}
.social-btns .btn.facebook .fa {
  /* color: #3b5998; */
  color: #fff;
}
.social-btns .btn.youtube:before {
  background-color: #ec252f;
}
.social-btns .btn.youtube .fa {
  /* color: #ec252f; */
  color: #fff;
}
.social-btns .btn.instagram:before {
  background-color: #517ea4;
}
.social-btns .btn.instagram .fa {
  /* color: #517ea4; */
  color: #fff;
}
.social-btns .btn.yelp:before {
  background-color: #b00;
}
.social-btns .btn.yelp .fa {
  /* color: #b00; */
  color: #fff;
}
.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
  color: #fff;
  -webkit-transform: scale(1);
          transform: scale(1);
}
/*.social-btns {
  height: 90px;
  margin: auto;
  font-size: 0;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}*/
.social-btns .btn {
  display: inline-block;
  background-color: #2a2a2a;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  /*box-shadow: 0 5px 15px -5px rgba(0,0,0,0.1);*/
  opacity: 0.99;
}
.social-btns .btn:before {
  content: '';
  width: 120%;
  height: 120%;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.social-btns .btn .fa {
  font-size: 38px;
  vertical-align: middle;
  margin-bottom: 15px;
}

.copyright {
  text-align: right;
}

.textwidget {
  margin-bottom: 1.7rem;
}