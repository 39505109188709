#navbar-custom {
  padding: 0rem 1rem;
  font-size: 13px;
  background-color: #000;
  /*flex-wrap: nowrap;*/
}

#nav-logo {
  padding-top: 0;
}

.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}

.small-spacing {
  margin: 0px 4px;
}

#navbar-toggler-custom {
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 4px 6px;
}

.FaCaretDown-custom {
  margin-top: -4px;
}

#nav-quote {
  height: 29px;
  background-color: #781824;
  cursor: pointer;
  opacity: 1.0;
  color: #fff;
  text-transform: uppercase;
  padding-top: 6px;
  padding-left: 11px;
  padding-right: 7px;
  letter-spacing: .2rem;
  position: fixed;
  z-index: 99;
  right: 93px;
  top: 3px;
}

#nav-quote:hover {
    opacity: 0.8;
    text-decoration: none;
}

/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)
*/@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)
*/@media (min-width: 768px) {

}

/*// Large devices (desktops, 992px and up)
*/@media (min-width: 992px) {
  .small-spacing {
    margin: 0px 0px;
  }
  #nav-quote {
    right: 12px;
  }
}

/*// Extra large devices (large desktops, 1200px and up)
*/@media (min-width: 1200px) {

}





@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.nav-link {
  position: relative;
  overflow: hidden;
}

.nav-link:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

.nav-link:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}