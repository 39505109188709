@font-face {
  font-family: 'Limerick-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Limerick-Regular.67984de0.ttf); /* IE9 Compat Modes */
  src: local('Limerick'), local('Limerick'),
       url(/static/media/Limerick-Regular.67984de0.ttf) format('embedded-opentype'), 
       url(/static/media/Limerick-Regular.67984de0.ttf) format('woff2'), 
       url(/static/media/Limerick-Regular.67984de0.ttf) format('woff'), 
       url(/static/media/Limerick-Regular.67984de0.ttf) format('truetype'), 
       url(/static/media/Limerick-Regular.67984de0.ttf) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Limerick-Light';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Limerick-Light.beb52967.ttf); /* IE9 Compat Modes */
  src: local('Limerick'), local('Limerick'),
       url(/static/media/Limerick-Light.beb52967.ttf) format('embedded-opentype'), 
       url(/static/media/Limerick-Light.beb52967.ttf) format('woff2'), 
       url(/static/media/Limerick-Light.beb52967.ttf) format('woff'), 
       url(/static/media/Limerick-Light.beb52967.ttf) format('truetype'), 
       url(/static/media/Limerick-Light.beb52967.ttf) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Limerick-Xlight';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Limerick-Xlight.69277adc.ttf); /* IE9 Compat Modes */
  src: local('Limerick'), local('Limerick'),
       url(/static/media/Limerick-Xlight.69277adc.ttf) format('embedded-opentype'), 
       url(/static/media/Limerick-Xlight.69277adc.ttf) format('woff2'), 
       url(/static/media/Limerick-Xlight.69277adc.ttf) format('woff'), 
       url(/static/media/Limerick-Xlight.69277adc.ttf) format('truetype'), 
       url(/static/media/Limerick-Xlight.69277adc.ttf) format('svg'); /* Legacy iOS */
}


@font-face {
  font-family: 'Watchworld-Thin';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Watchword_Thin.b3fd5c9f.otf); /* IE9 Compat Modes */
  src: local('Watchworld'), local('Watchworld'),
       url(/static/media/Watchword_Thin.b3fd5c9f.otf) format('embedded-opentype'), 
       url(/static/media/Watchword_Thin.b3fd5c9f.otf) format('woff2'), 
       url(/static/media/Watchword_Thin.b3fd5c9f.otf) format('woff'), 
       url(/static/media/Watchword_Thin.b3fd5c9f.otf) format('truetype'), 
       url(/static/media/Watchword_Thin.b3fd5c9f.otf) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Watchworld-Hairline';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Watchword_Hairline.1c1c61b0.otf); /* IE9 Compat Modes */
  src: local('Watchworld'), local('Watchworld'),
       url(/static/media/Watchword_Hairline.1c1c61b0.otf) format('embedded-opentype'), 
       url(/static/media/Watchword_Hairline.1c1c61b0.otf) format('woff2'), 
       url(/static/media/Watchword_Hairline.1c1c61b0.otf) format('woff'), 
       url(/static/media/Watchword_Hairline.1c1c61b0.otf) format('truetype'), 
       url(/static/media/Watchword_Hairline.1c1c61b0.otf) format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Watchworld-Regular';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Watchword_Regular.f3bde9ef.otf); /* IE9 Compat Modes */
  src: local('Watchworld'), local('Watchworld'),
       url(/static/media/Watchword_Regular.f3bde9ef.otf) format('embedded-opentype'), 
       url(/static/media/Watchword_Regular.f3bde9ef.otf) format('woff2'), 
       url(/static/media/Watchword_Regular.f3bde9ef.otf) format('woff'), 
       url(/static/media/Watchword_Regular.f3bde9ef.otf) format('truetype'), 
       url(/static/media/Watchword_Regular.f3bde9ef.otf) format('svg'); /* Legacy iOS */
}

html {
  font-size: 16px;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Limerick-Light', Helvetica Neue, Helvetica, sans-serif;
  text-transform: uppercase;
  background-color: #000;
  color: #fff;
  letter-spacing: 5px;
  text-align: center;
  /*font-size: 62.5%;*/
}

section {
}



html {
  overflow-x: hidden;
}

body {
  padding-top: 35px;
  overflow-x: hidden;
}

button {
  background: none;
  border: none;
  margin: 0;
  color: white;
  font-family: 'Limerick-Light', Helvetica Neue, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
}

button:focus {
  outline: 0;
}
#GoogleAuthBtn {
  border-color: #333;
  background-color: #fff;
  color: #333;

  border: 1px solid;
  font-family: inherit;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 9px 16px;
  text-align: center;
  vertical-align: middle; 
}

#GoogleAuthIcon {
  width: 20px;
  margin-right: 20px;
  margin-bottom: 4px;
}

#GoogleAuthBtn:hover {
  background-color: #d4d4d4;
  text-decoration: none;
}
#FacebookAuthBtn {
  border-color: #333;
  background-color: #3b5998;
  color: #fff;

  border: 1px solid;
  font-family: inherit;
  font-size: 13px;
  height: 40px;
  line-height: 40px;
  margin: 0;
  padding: 9px 6px;
  text-align: center;
  vertical-align: middle; 
}

#FacebookAuthIcon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 4px;
}

#FacebookAuthBtn:hover {
  background-color: #294175;
  text-decoration: none;
}
.show {
  display: block;
}

.hide {
  display: none;
}

.authmodal {
  color: black;
}

.auth-divider {
  margin-top: 20px;
  margin-bottom: 20px;
}
#navbar-custom {
  padding: 0rem 1rem;
  font-size: 13px;
  background-color: #000;
  /*flex-wrap: nowrap;*/
}

#nav-logo {
  padding-top: 0;
}

.navbar-toggler-icon {
  width: 1em;
  height: 1em;
}

.small-spacing {
  margin: 0px 4px;
}

#navbar-toggler-custom {
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 4px 6px;
}

.FaCaretDown-custom {
  margin-top: -4px;
}

#nav-quote {
  height: 29px;
  background-color: #781824;
  cursor: pointer;
  opacity: 1.0;
  color: #fff;
  text-transform: uppercase;
  padding-top: 6px;
  padding-left: 11px;
  padding-right: 7px;
  letter-spacing: .2rem;
  position: fixed;
  z-index: 99;
  right: 93px;
  top: 3px;
}

#nav-quote:hover {
    opacity: 0.8;
    text-decoration: none;
}

/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)
*/@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)
*/@media (min-width: 768px) {

}

/*// Large devices (desktops, 992px and up)
*/@media (min-width: 992px) {
  .small-spacing {
    margin: 0px 0px;
  }
  #nav-quote {
    right: 12px;
  }
}

/*// Extra large devices (large desktops, 1200px and up)
*/@media (min-width: 1200px) {

}





@-webkit-keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
  }
}





@keyframes ripple {
  0% {
    -webkit-transform: scale(0, 0);
            transform: scale(0, 0);
    opacity: 1;
  }
  20% {
    -webkit-transform: scale(25, 25);
            transform: scale(25, 25);
    opacity: 1;
  }
  100% {
    opacity: 0;
    -webkit-transform: scale(40, 40);
            transform: scale(40, 40);
  }
}

.nav-link {
  position: relative;
  overflow: hidden;
}

.nav-link:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, .5);
  opacity: 0;
  border-radius: 100%;
  -webkit-transform: scale(1, 1) translate(-50%);
          transform: scale(1, 1) translate(-50%);
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
}

.nav-link:focus:not(:active)::after {
  -webkit-animation: ripple 1s ease-out;
          animation: ripple 1s ease-out;
}

#herovid {
  position: absolute;
  display: block;
  padding: 0;
  overflow: hidden;

  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
  -webkit-animation: fadein 4s;
          animation: fadein 4s;
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  opacity: 0;

}

@-webkit-keyframes fadein {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
    display: none;
  }
  100% {
    opacity: 1;
    display: block;
  }
}



    



/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  #herovid {

  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #herovid {

  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #herovid {

  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #herovid {

  }
}






#hero-wrapper {
  position: relative;
  /*height: 100%;*/
}

#hero {
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: 640px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/
  .hero-line {
    font-size: 2rem;
  }
  .lead {
    font-size: .9rem;
  }

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) { 
  #hero {

  }
  .hero-line {

  }
  .lead {

  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {

}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #hero {

  }
  .hero-line {
    font-size: 3.5rem;
  }
  .lead {
    font-size: 1.3rem;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #hero {
    padding-top: 160px;
    padding-bottom: 160px;
    min-height: 819px;
  }
  .hero-line {
    font-size: 4.5rem;
  }
  .lead {

  }
}







@keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
    from { opacity: 1; }
    to   { opacity: 0; }
}

/* Internet Explorer */

/* Opera < 12.1 */

#hero {
  -webkit-animation: fadeout 4s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
          animation: fadeout 4s;
  -webkit-animation-delay: 4s;
          animation-delay: 4s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
}







@-webkit-keyframes intro {
  0% {
    opacity: 0;
    /*z-index: 0;*/
  }
  50% {
    opacity: 1;
    /*z-index: 1;*/
  }
  100% {
    opacity: 0;
    /*z-index: 0;*/
  }
}







@keyframes intro {
  0% {
    opacity: 0;
    /*z-index: 0;*/
  }
  50% {
    opacity: 1;
    /*z-index: 1;*/
  }
  100% {
    opacity: 0;
    /*z-index: 0;*/
  }
}

#hero-container {
  position: relative;
  /*z-index: 1;*/
  opacity: 0;
  -webkit-animation: intro 8s;
          animation: intro 8s;
}






@keyframes enterup {
    from { -webkit-transform: translateY(40px); transform: translateY(40px); }
    to   { -webkit-transform: translateY(0); transform: translateY(0); }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes enterup {
    from { -webkit-transform: translateY(40px); transform: translateY(40px); }
    to   { -webkit-transform: translateY(0); transform: translateY(0); }
}

/* Internet Explorer */

/* Opera < 12.1 */

#hero-img {
  -webkit-animation: enterup 2s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
          animation: enterup 2s;
}














#notification {
  border: 12px solid white;
  border-bottom: 0px;
}

.noti-img {
  /* border: 6px solid white; */
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .noti-img {

  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .noti-img {

  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .noti-img {
    max-width: 50%;
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .noti-img {

  }
}
#featured {
  border: 6px solid white;
}

.feat-img {
  border: 6px solid white;
}
#vs {
  padding-top: 60px;
  padding-bottom: 60px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.vs-title {
  font-size: 1.5rem;
}

.vs-subtitle {
  font-size: 1rem;
  margin-bottom: 30px;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  #vs {

  }
  .vs-title {

  }
  .vs-subtitle {
    
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #vs {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .vs-title {
    font-size: 2.5rem;
  }
  .vs-subtitle {
    font-size: 1.75rem;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #vs {

  }
  .vs-title {

  }
  .vs-subtitle {
    
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #vs {

  }
  .vs-title {

  }
  .vs-subtitle {
    
  }
}
#trends {
/*  padding-top: 60px;
  padding-bottom: 60px;*/
  border: 12px solid #fff;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.trends-title {
  font-size: 1.5rem;
}

.trends-subtitle {
  font-size: 1rem;
  margin-bottom: 30px;
}

.trends-img {
  max-height: 950px;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  #trends {

  }
  .trends-title {

  }
  .trends-subtitle {
    
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #trends {
/*    padding-top: 160px;
    padding-bottom: 160px;*/
  }
  .trends-title {
    font-size: 2.5rem;
  }
  .trends-subtitle {
    font-size: 1.75rem;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  #trends {

  }
  .trends-title {

  }
  .trends-subtitle {
    
  }
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  #trends {

  }
  .trends-title {

  }
  .trends-subtitle {
    
  }
}
#features {
  /*height: 740px;*/
  background-color: #fff;
  color: #fff;
  text-shadow: 0 1px 0 black;
  border: 6px solid #fff;
}

#feat1 {
  background: 
  -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ),
  url(/static/media/overflow-RGB.00feae20.jpg) no-repeat center center;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ),
  url(/static/media/overflow-RGB.00feae20.jpg) no-repeat center center;
  background-size: cover;
}

#feat2 {
  background: 
  -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/light-RGB.65aa0269.jpg) no-repeat center center;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/light-RGB.65aa0269.jpg) no-repeat center center;
  background-size: cover;
}

#feat3 {
  background-color: black;
  background: 
  -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/footrest-RGB.458a46af.jpg) no-repeat right center;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/footrest-RGB.458a46af.jpg) no-repeat right center;
  background-size: cover;
}

#feat4 {
  background: 
  -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/showerheads-RGB.6dcb2a74.jpg) no-repeat center center;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/showerheads-RGB.6dcb2a74.jpg) no-repeat center center;
  background-size: cover;
}

#feat5 {
  background: 
  -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/pullhandle-RGB.ee2a01d3.jpg) no-repeat center center;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/pullhandle-RGB.ee2a01d3.jpg) no-repeat center center;
  background-size: cover;
}

#feat6 {
  background: 
  -webkit-linear-gradient(
    bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/tray-RGB.056492d7.jpg) no-repeat center center;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(/static/media/tray-RGB.056492d7.jpg) no-repeat center center;
  background-size: cover;
}


.feature-box {
  height: 480px;
  background-color: #fafafa;
  /*flex: 1 0 32%;*/
  /*margin: 6px;*/
  padding-top: 2rem;
  /*margin: 2px;*/
  border: 6px solid white;
}

/* .overlay {
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  background: inherit;
  background-attachment: fixed;
} */

/*.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  transform: scale(2) translateY(20px);
}*/

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 55%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(15px);
  filter: blur(15px);
  -webkit-transform: scale(1.5) translateY(25px) translateX(96px);
  transform: scale(1.5) translateY(25px) translateX(96px);
}

.feat-title {
  margin: 0;
  color: white;
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
}

.feat-subtitle {
  display: none;
}

.feat-pic {
  margin-top: 50px;
  width: 250px;
}



/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  /* .feature-box {

  } */
  .feat-title {
    font-size: 1.5rem;
  }
  /* .feat-subtitle {

  }
  .feat-pic {

  } */
}

/*// Medium devices (tablets, 768px and up)*/
/* @media (min-width: 768px) {
  .feature-box {

  }
  .feat-title {

  }
  .feat-subtitle {

  }
  .feat-pic {
    
  }
  #feat1 {

  }
  #feat2 {
    
  }
  #feat3 {

  }
  #feat4 {
    
  }
  #feat5 {

  }
  #feat6 {

  }
} */

/*// Large devices (desktops, 992px and up)*/
/* @media (min-width: 992px) {
  .feature-box {

  }
  .feat-title {

  }
  .feat-subtitle {

  }
  .feat-pic {
    
  }
} */

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  /* .feature-box {

  } */
  .feat-title {
    font-size: 2rem;
  }
  /* .feat-subtitle {

  }
  .feat-pic {
    
  } */
}









#products {
  padding-top: 110px;
  padding-bottom: 110px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );
}

#products-main {
  height: 1px;
  width: 100px;
  margin-top: -45px;
  margin-bottom: 45px;  
}

.title {
  margin-bottom: 50px;
}

.chair-main-img {
  height: 330px;
}

.chair-selection-section {
  margin-bottom: 30px;
}

.chair-name {
  margin-bottom: 8px;
}

.chair-img {
  margin-bottom: 15px;

  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;

  top: 0px;
  display: inline-block;
  position: relative;

  box-shadow: 0px 0px 0px rgba( 197, 197, 197, 0.1 );

  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.chair-img:hover {
  top: -5px;
  box-shadow: 0px 5px 10px rgba( 197, 197, 197, 0.2 );
}

.chair-img:active {
  top: 0px;
}


.chair-section {
  margin-bottom: 20px;
}

.seal {
  position: absolute;
  right: 0;
  margin-right: 50px;
  width: 75px;
}

.lead-legal {
  font-size: 12px;
  letter-spacing: .1rem;
  margin-top: 10px;
  color: #636363;
}

.lead-msrp {
  margin-bottom: 40px;
}


.line {
  width: 3px;
  position: absolute;
  left: 50%;
  margin-left: -3px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(76,76,76,1)), color-stop(0%, rgba(102,102,102,1)), color-stop(25%, rgba(71,71,71,1)), color-stop(50%, rgba(44,44,44,1)), color-stop(75%, rgba(17,17,17,1)), color-stop(100%, rgba(17,17,17,1)));
  background: -webkit-linear-gradient(top, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  background: linear-gradient(to bottom, rgba(76,76,76,1) 0%, rgba(102,102,102,1) 0%, rgba(71,71,71,1) 25%, rgba(44,44,44,1) 50%, rgba(17,17,17,1) 75%, rgba(17,17,17,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#111111', GradientType=0 );

  display: block;
}

.linechair {
  height: 250px;
}

.linebowl {
  height: 250px;
}

.linebase {
  height: 85px;
}

.color-title {
  font-size: .8rem;
}

.colorbox {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;


  height: 50px;
  width: 70px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 5px;
  top: 0px;
  display: inline-block;
  position: relative;

  box-shadow: 0px 0px 0px rgba( 197, 197, 197, 0.1 );

  -webkit-transform: translateZ(0);
          transform: translateZ(0);
  
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.colorbox:hover {
  top: -5px;
  box-shadow: 0px 5px 10px rgba( 197, 197, 197, 0.2 );
}

.colorbox:active {
  top: 0px;
}

.vip-height-mod {
  height: 175px;
}









/**********************************************/
/***** MOBILE SELECT COLOR BAR PROPERTIES *****/
/**********************************************/

/*****/
/* CHAIRCOLOR SELECT BAR */
/*****/
#chaircolor-select-bar {
  padding: 5px;
  padding-bottom: 0px;
  overflow-x: scroll;
  white-space: nowrap;

}
#chaircolor-select-bar:target {
  background-color: red;
}

.chaircolor-select-bar-title {
  float: left;
  color: #000;
  font-size: 1.25rem;
  letter-spacing: .1rem;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;
}
/*****/
/* /CHAIRCOLOR SELECT BAR */
/*****/


/*****/
/* BASECOLOR SELECT BAR */
/*****/
#bowlcolor-select-bar {
  padding: 5px;
  padding-bottom: 0px;
  overflow-x: scroll;
  white-space: nowrap;
  
}
.bowlcolor-select-bar-title {
  float: left;
  color: #000;
  font-size: 1.25rem;
  letter-spacing: .1rem;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 5px;  
}
/*****/
/* /BASECOLOR SELECT BAR */
/*****/

/*****/
/* BOTH */
/*****/
.colorbox-bar {
  background: transparent;
  border: 0;
  padding: 0;
  cursor: pointer;
  outline: 0;
  -webkit-appearance: none;


  height: 50px;
  width: 72px;
  margin-right: 5px;
  top: 0px;
  display: inline-block;
  position: relative;

  box-shadow: 0 0 5px rgba(97, 97, 97, .1);
  border: 1px solid rgba(97, 97, 97, .1);
  
  -webkit-transition: all 0.2s ease;
          transition: all 0.2s ease;
}

.colorbox-bar:hover {
  top: -3px;
  box-shadow: 0 0 5px rgba(97, 97, 97, .1);
  border: 1px solid rgba(97, 97, 97, .1);
}

.colorbox-bar:active {
  top: 0px;
  box-shadow: 0 0 5px rgba(97, 97, 97, 1);
  border: 1px solid rgba(97, 97, 97, 1);
}

.colorbox-bar-img {
  width: 100%;
  height: 100%;
}


.fixed-bottom-custom {
  position: fixed;
  right: 0;
  bottom: -250px;
  left: 0;
  z-index: 1030;
  -webkit-transition: bottom .1s linear 0s;
  transition: bottom .1s linear 0s;
}

.scrollingOver {
  -webkit-transition: bottom .1s linear 0s;
  transition: bottom .1s linear 0s;
  bottom: 0px;
}

/*****/
/* BOTH */
/*****/



/**********************************************/
/***** MOBILE SELECT COLOR BAR PROPERTIES *****/
/**********************************************/





















/**********************************************/
/***** COLORS *****/
/**********************************************/


.black {
  background-color: rgb(0, 0, 0, 1);
}

.white {
  background-color: rgb(255, 255, 255, 1);
}

.fossilgrey {
  /*background-color: rgb(186, 182, 178, 1);*/
  background-color: rgb(162, 149, 135, 1);
}

.cappuccino {
  background-color: rgb(194, 145, 87, 1); 
}

.ivory {
  /*background-color: rgb(255, 255, 240, 1);*/
  background-color: rgb(255, 255, 241, 1);
}

.burgundy {
  /*background-color: rgb(128, 0, 38, 1);*/
  background-color: rgb(140, 2, 31, 1);
}

.darkchocolate {
  background-color: rgb(67, 57, 50, 1);
  /*background-color: rgb(44, 24, 0, 1);*/
}

/*.rosegold {
  background: rgba(240,133,179,1);
  background: -moz-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(240,133,179,1)), color-stop(51%, rgba(240,161,195,1)), color-stop(100%, rgba(142,69,116,1)));
  background: -webkit-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: -o-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: -ms-linear-gradient(left, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  background: linear-gradient(to right, rgba(240,133,179,1) 0%, rgba(240,161,195,1) 51%, rgba(142,69,116,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f085b3', endColorstr='#8e4574', GradientType=1 );
}*/

/*.gold {
  background: rgba(191,145,46,1);
  background: -moz-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(191,145,46,1)), color-stop(51%, rgba(246,255,122,1)), color-stop(100%, rgba(128,85,4,1)));
  background: -webkit-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: -o-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: -ms-linear-gradient(left, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  background: linear-gradient(to right, rgba(191,145,46,1) 0%, rgba(246,255,122,1) 51%, rgba(128,85,4,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bf912e', endColorstr='#805504', GradientType=1 );
}*/

/*.pearl {
  background-color: rgb(255, 255, 240, 1);
}

.graphite {
  background-color: rgb(129, 130, 130, 1);  
}

.mocha {
  background-color: rgb(67, 57, 50, 1);
}*/


/******************/
/***** /COLORS *****/
/******************/























/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/
  .lead-products {
      font-size: 1.5rem;
  }
  .display-4 {
    font-size: 2rem;
  }
  .col-custom {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;    
  }
/* For all sizes */
@media(min-width: 0px) {
  #spachair-colors-display, .linechair, .linebowl, .linebase {
    display: none;
  }
}
/* Mobile L devices (425px and up) */
@media (min-width: 425px) {
  .linechair, .linebowl, .linebase {
    display: block;
  }
}
/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  .lead-products {
      font-size: 1.5rem;
  }
  .seal {
    margin-right: 130px;
  }
  .col-custom {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;    
  }
}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  #products {
    padding-top: 160px;
    padding-bottom: 160px;
  }
  .line {
    display: none;
  }
  .display-4 {
    font-size: 2.5rem;
  }
  .seal {
    margin-right: 25px;
  }
  .col-custom {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .vip-height-mod {
    height: 200px;
  }  
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
  .line {
    display: block;
  }
  .color-title {
    font-size: .6rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .seal {
    margin-right: 30px;
  }
  .col-custom {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;    
  }  
  #chaircolor-select-bar {
    padding: 5px;
    display: none;
  }
  #bowlcolor-select-bar {
    padding: 5px;
    display: none;
  }  
  #width-max-lg {
    max-width: 380px;
  }
  #spachair-colors-display {
    display: block;
  }
  .vip-height-mod {
    height: 335px;
  }   
  .chair-main-img {
    height: 290px;
  }  
}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  .color-title {
    font-size: .8rem;
  }
  .display-4 {
    font-size: 3.5rem;
  }
  .seal {
    margin-right: 45px;
  }
  .col-custom {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;    
  }  
  .chair-main-img {
    height: 400px;
  }  
}










.accessories-chairs-title {
  margin-top: 40px;
}

/*.accessories-chairs-titles {
  font-size: .5rem;
}*/

.accessories-chairs-section {
  margin-bottom: 40px;
}

.accessories-chairs {
  margin: 5px;
}

.accessories-title {
  margin-top: 80px;
}

.lead-products-table {
  margin-top: 40px;
}
@media(min-width: 992px) {
  #accessories-color-display {
    display: block !important;
  }
}
#videosfp {
  /*height: 740px;*/
  background-color: #fff;
  color: #fff;
  /*text-shadow: 0 1px 0 black;*/
  border: 6px solid #fff;
}

/* #videofp1 {

}

#videofp2 {
  
}

#videofp3 {
  
}

#videofp4 {
  
}

#videofp5 {
  
}

#videofp6 {
  
} */


.videofp-box {
  height: 315px;
  border: 6px solid white;
  width: 100%;
  padding: 0;

}

.videofp-item {
  width: 100%;
  height: 100%;
}

.videofp-header {
  color: #000;
  padding-top: 10px;
}



#about {
  text-transform: uppercase;
  text-align: center;
}
#jet {
  padding-top: 60px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.jet-title {
  font-size: 1.5rem;
}

.jet-subtitle {
  font-size: 1rem;
  margin-bottom: 30px;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .jet-title {
    font-size: 2.5rem;
  }
  .jet-subtitle {
    font-size: 1.75rem;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}


#patent {
  padding-top: 160px;
  padding-bottom: 160px;
}

.patent-head {
  margin-bottom: 40px;
}

.patent-title {
  letter-spacing: 0.1rem;
}

.patent-number {
  letter-spacing: 0.1rem;
}

.patent-img {
  margin-bottom: 30px;
}
#howto {
  padding-top: 160px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.howto-header {
  font-size: 2.5rem;
  margin-bottom: 40px;
}

.step-img {
  border-radius: 500px;
  margin-bottom: 20px;
}

.howto-subtitle {
  margin-bottom: 50px;
}

/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}
#contact {
  padding-top: 60px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.contact-title {
  margin-bottom: 30px;
}

.contact-info {
  letter-spacing: .1rem;
}

.map {
  width: 100%;
  height: 100%;
}
#faq {
  color: #000;
  padding-top: 60px;
  padding-bottom: 160px;
  letter-spacing: .1rem;
  text-transform: capitalize;
  line-height: 1.25rem;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

#faq ul a {
  color: #fff;
}

.accordion button {
  color: #000;
  white-space: normal;
}

.accordion button:hover {
  color: #000;
}

#faq ul li {
  margin-bottom: 5px;
}


#faq-title {
  margin-bottom: 30px;
  color: #fff;
}

#faq-toc {
  color: #fff;
  text-align: left;
}

#faq-toc .toc-btn-custom {
  white-space: normal;
  color: #fff;
  text-align: left;
}

.btn-link {
  text-transform: capitalize;
  font-weight:bold;
}

.faq-toc-headers {
  text-align: center;
}

/*.card {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.card-header {
  overflow-wrap: break-word;
  word-wrap: break-word;
}*/

.card-body {
  text-transform: none;
  padding-left: 8%;
  padding-right: 8%;
}

#downloads {
  padding-top: 60px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.dl-title {
  margin-bottom: 30px;
}

#downloads-cards {
  color: #000;
  letter-spacing: .1rem;
  margin-top: 15px;
}

.dl-container {
  margin-bottom: 30px;
}
#gallery {
  padding-top: 60px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.gallery-title {
  margin-bottom: 30px;
}


.image-gallery-fullscreen-button:active, .image-gallery-fullscreen-button:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.image-gallery-play-button:active, .image-gallery-play-button:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.image-gallery-right-nav:active, .image-gallery-right-nav:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.image-gallery-left-nav:active, .image-gallery-left-nav:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}
#videos {
  /*height: 740px;*/
  background-color: #fff;
  color: #fff;
  /*text-shadow: 0 1px 0 black;*/
  border: 0px solid #fff;
}

#main-videos {
  border: 6px solid #fff;
}

/* #video1 {

}

#video2 {
  
}

#video3 {
  
}

#video4 {
  
}

#video5 {
  
}

#video6 {
  
} */


.video-box {
  height: 315px;
  border: 6px solid white;
  width: 100%;
  padding: 0;

}

.video-item {
  width: 100%;
  height: 100%;
}

.video-header {
  color: #000;
  padding-top: 50px;
  padding-bottom: 10px;
}



#nomagnets {
  padding-top: 60px;
  padding-bottom: 60px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.nomagnets-title {
  font-size: 1.5rem;
}

.nomagnets-subtitle {
  font-size: 1rem;
  margin-bottom: 30px;
}

.nomagnets-description {
  letter-spacing: .1rem;
  text-transform: none;
}

#nomagnets .card {
  color: #000;
  letter-spacing: .1rem;
  margin-bottom: 30px;
}

.nomagnets-link {
  text-transform: uppercase;
}

.nomagnets-video {
  height: 300px;
}


/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
/* @media (min-width: 576px) {
  #nomagnets {

  }
  .nomagnets-title {

  }
  .nomagnets-subtitle {
    
  }
} */

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  /* #nomagnets {
    padding-top: 160px;
    padding-bottom: 160px;
  } */
  .nomagnets-title {
    font-size: 2.5rem;
  }
  .nomagnets-subtitle {
    font-size: 1.75rem;
  }
}

/*// Large devices (desktops, 992px and up)*/
/* @media (min-width: 992px) {
  #nomagnets {

  }
  .nomagnets-title {

  }
  .nomagnets-subtitle {
    
  }
} */

/*// Extra large devices (large desktops, 1200px and up)*/
/* @media (min-width: 1200px) {
  #nomagnets {

  }
  .nomagnets-title {

  }
  .nomagnets-subtitle {
    
  }
} */
#footer {
  background: #2a2a2a;
  color: #6e6e6e;
  font-size: 12px;
  padding: 70px 0 50px;
  letter-spacing: .01rem;
  text-align: center;
}



/*@import '//codepen.io/chrisdothtml/pen/ojLzJK.css';*/
.social-btns .btn,
.social-btns .btn:before,
.social-btns .btn .fa {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
          transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns .btn .fa {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.social-btns .btn.facebook:before {
  background-color: #3b5998;
}
.social-btns .btn.facebook .fa {
  /* color: #3b5998; */
  color: #fff;
}
.social-btns .btn.youtube:before {
  background-color: #ec252f;
}
.social-btns .btn.youtube .fa {
  /* color: #ec252f; */
  color: #fff;
}
.social-btns .btn.instagram:before {
  background-color: #517ea4;
}
.social-btns .btn.instagram .fa {
  /* color: #517ea4; */
  color: #fff;
}
.social-btns .btn.yelp:before {
  background-color: #b00;
}
.social-btns .btn.yelp .fa {
  /* color: #b00; */
  color: #fff;
}
.social-btns .btn:focus:before,
.social-btns .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns .btn:focus .fa,
.social-btns .btn:hover .fa {
  color: #fff;
  -webkit-transform: scale(1);
          transform: scale(1);
}
/*.social-btns {
  height: 90px;
  margin: auto;
  font-size: 0;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}*/
.social-btns .btn {
  display: inline-block;
  background-color: #2a2a2a;
  width: 75px;
  height: 75px;
  line-height: 75px;
  margin: 0 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 28%;
  /*box-shadow: 0 5px 15px -5px rgba(0,0,0,0.1);*/
  opacity: 0.99;
}
.social-btns .btn:before {
  content: '';
  width: 120%;
  height: 120%;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.social-btns .btn .fa {
  font-size: 38px;
  vertical-align: middle;
  margin-bottom: 15px;
}

.copyright {
  text-align: right;
}

.textwidget {
  margin-bottom: 1.7rem;
}
#thankyou {
  padding-top: 60px;
  padding-bottom: 60px;

  background: rgba(76,76,76,1);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-radial-gradient(center, ellipse, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

#thankyou-btn {
  margin-top: 20px;
}
.social-btns-side {
  position: fixed;
  top: 30%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 1001;
  display: none;
}

/* Style the icon bar links */
.social-btns-side a {
  display: block;
  text-align: center;
  padding: 12px;
  /*transition: all 0.3s ease;*/
  color: white;
  /*font-size: 20px;*/
}



.social-btns-side .btn,
.social-btns-side .btn:before,
.social-btns-side .btn .fa {
  -webkit-transition: all 0.35s;
  transition: all 0.35s;
  -webkit-transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
          transition-timing-function: cubic-bezier(0.31, -0.105, 0.43, 1.59);
}
.social-btns-side .btn:before {
  top: 90%;
  left: -110%;
}
.social-btns-side .btn .fa {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}
.social-btns-side .btn.facebook:before {
  background-color: #3b5998;
}
.social-btns-side .btn.facebook .fa {
  /* color: #3b5998; */
  color: #fff;
}
.social-btns-side .btn.youtube:before {
  background-color: #ec252f;
}
.social-btns-side .btn.youtube .fa {
  /* color: #ec252f; */
  color: #fff;
}
.social-btns-side .btn.instagram:before {
  background-color: #517ea4;
}
.social-btns-side .btn.instagram .fa {
  /* color: #517ea4; */
  color: #fff;
}
.social-btns-side .btn.yelp:before {
  background-color: #b00;
}
.social-btns-side .btn.yelp .fa {
  /* color: #b00; */
  color: #fff;
}
.social-btns-side .btn:focus:before,
.social-btns-side .btn:hover:before {
  top: -10%;
  left: -10%;
}
.social-btns-side .btn:focus .fa,
.social-btns-side .btn:hover .fa {
  color: #fff;
  -webkit-transform: scale(1);
          transform: scale(1);
}
.social-btns-side .btn {
  background-color: #2a2a2a;
  width: 50px;
  height: 50px;
  line-height: 0px;
  margin: 0 0px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 0%;
  opacity: 0.99;
}
.social-btns-side .btn:before {
  content: '';
  width: 120%;
  height: 120%;
  position: absolute;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.social-btns-side .btn .fa {
  font-size: 25px;
  vertical-align: middle;
  margin-bottom: 15px;
}




/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*// Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  .social-btns-side {
    display: block;
  }
}

/*// Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {

}

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {

}
    .ScrollUpButton__Container{
      background-color: rgb(255, 255, 255);
      border-radius: 50%;
      border: 5px solid #23232303;
      height: 50px;
      position: fixed;
      bottom: 20px;
      width: 50px;
      -webkit-transition: all 0.5s ease-in-out;
      transition: all 0.5s ease-in-out;
      -webkit-transition-property: opacity, right;
      transition-property: opacity, right;
      cursor: pointer;
      opacity: 0;
      right: -75px;
    }

    .ScrollUpButton__Toggled{
      opacity: 1;
      right: 20px;
    }
