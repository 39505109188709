#gallery {
  padding-top: 60px;
  padding-bottom: 160px;

  background: rgba(76,76,76,1);
  background: -moz-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%, rgba(76,76,76,1)), color-stop(50%, rgba(43,43,43,1)), color-stop(100%, rgba(19,19,19,1)));
  background: -webkit-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -o-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: -ms-radial-gradient(center, ellipse farthest-corner, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  background: radial-gradient(ellipse at center, rgba(76,76,76,1) 0%, rgba(43,43,43,1) 50%, rgba(19,19,19,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#131313', GradientType=1 );

}

.gallery-title {
  margin-bottom: 30px;
}


.image-gallery-fullscreen-button:active, .image-gallery-fullscreen-button:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.image-gallery-play-button:active, .image-gallery-play-button:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.image-gallery-right-nav:active, .image-gallery-right-nav:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}

.image-gallery-left-nav:active, .image-gallery-left-nav:focus {
  outline: 0;
  border: none;
  -moz-outline-style: none;
}