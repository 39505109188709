#patent {
  padding-top: 160px;
  padding-bottom: 160px;
}

.patent-head {
  margin-bottom: 40px;
}

.patent-title {
  letter-spacing: 0.1rem;
}

.patent-number {
  letter-spacing: 0.1rem;
}

.patent-img {
  margin-bottom: 30px;
}