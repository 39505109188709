#videosfp {
  /*height: 740px;*/
  background-color: #fff;
  color: #fff;
  /*text-shadow: 0 1px 0 black;*/
  border: 6px solid #fff;
}

/* #videofp1 {

}

#videofp2 {
  
}

#videofp3 {
  
}

#videofp4 {
  
}

#videofp5 {
  
}

#videofp6 {
  
} */


.videofp-box {
  height: 315px;
  border: 6px solid white;
  width: 100%;
  padding: 0;

}

.videofp-item {
  width: 100%;
  height: 100%;
}

.videofp-header {
  color: #000;
  padding-top: 10px;
}


