#features {
  /*height: 740px;*/
  background-color: #fff;
  color: #fff;
  text-shadow: 0 1px 0 black;
  border: 6px solid #fff;
}

#feat1 {
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ),
  url(./images/overflow-RGB.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#feat2 {
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(./images/light-RGB.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#feat3 {
  background-color: black;
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(./images/footrest-RGB.jpg) no-repeat right center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#feat4 {
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(./images/showerheads-RGB.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#feat5 {
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(./images/pullhandle-RGB.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

#feat6 {
  background: 
  linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.30)
  ), 
  url(./images/tray-RGB.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}


.feature-box {
  height: 480px;
  background-color: #fafafa;
  /*flex: 1 0 32%;*/
  /*margin: 6px;*/
  padding-top: 2rem;
  /*margin: 2px;*/
  border: 6px solid white;
}

/* .overlay {
 position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px 10px;
  background: inherit;
  background-attachment: fixed;
} */

/*.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(12px);
  filter: blur(12px);
  transform: scale(2) translateY(20px);
}*/

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 60%;
  height: 55%;
  background: inherit;
  background-attachment: fixed;
  -webkit-filter: blur(15px);
  filter: blur(15px);
  -webkit-transform: scale(1.5) translateY(25px) translateX(96px);
  -ms-transform: scale(1.5) translateY(25px) translateX(96px);
  transform: scale(1.5) translateY(25px) translateX(96px);
}

.feat-title {
  margin: 0;
  color: white;
  position: relative;
  z-index: 1;
  font-size: 1.5rem;
}

.feat-subtitle {
  display: none;
}

.feat-pic {
  margin-top: 50px;
  width: 250px;
}



/*// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap*/

/*// Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  /* .feature-box {

  } */
  .feat-title {
    font-size: 1.5rem;
  }
  /* .feat-subtitle {

  }
  .feat-pic {

  } */
}

/*// Medium devices (tablets, 768px and up)*/
/* @media (min-width: 768px) {
  .feature-box {

  }
  .feat-title {

  }
  .feat-subtitle {

  }
  .feat-pic {
    
  }
  #feat1 {

  }
  #feat2 {
    
  }
  #feat3 {

  }
  #feat4 {
    
  }
  #feat5 {

  }
  #feat6 {

  }
} */

/*// Large devices (desktops, 992px and up)*/
/* @media (min-width: 992px) {
  .feature-box {

  }
  .feat-title {

  }
  .feat-subtitle {

  }
  .feat-pic {
    
  }
} */

/*// Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
  /* .feature-box {

  } */
  .feat-title {
    font-size: 2rem;
  }
  /* .feat-subtitle {

  }
  .feat-pic {
    
  } */
}








