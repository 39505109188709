html {
  overflow-x: hidden;
}

body {
  padding-top: 35px;
  overflow-x: hidden;
}

button {
  background: none;
  border: none;
  margin: 0;
  color: white;
  font-family: 'Limerick-Light', Helvetica Neue, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
}

button:focus {
  outline: 0;
}